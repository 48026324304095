.headerstyle
{
    font-size: 12;
    font-family: 'Nunito';
    color: white;
    // cursor: pointer; 
    text-align: center;
}
.top
{
    background-color: white !important;
    color: #333333 !important;
}
.menuicon
{
    justify-content: flex-start;
}
.formText
{
    font-family: 'Nunito' !important;
    font-size: 12px !important;
    line-height: 1.5;
    // font-weight: bold !important;
}

.creditText{
    font-family: 'Nunito' !important;
    font-size: 15px !important;
    line-height: 1.5;
    //font-weight: bold;
    font-weight: bold !important;
}

.TextField-without-border-radius fieldset 
{
    border-radius: 2;
}
.formTextflag
{
    padding-bottom: 2px !important;
    font-family: 'Nunito'!important;
    color: #0A2638;
    font-weight: bold !important;
    font-size: 20px !important;
}
.heading
{
    font-size: 45px;
    color: white;
    display: flex ;
    text-transform: uppercase;
    font-family: 'Nunito' !important;
    font-weight: bold;
}
.banner__items 
{
    height: 289;
    background: url(../assets/images/person.jpg);
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    background-color: #0A2638;
}
.menu
{
    flex: 1 1 auto;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-family: 'Tiempos' !important;
    font-weight: 'bold';
}
.stepLabel
{
    font-family: 'Nunito' !important;
    font-size: 20px !important;
    color: #0A2638 !important;
}

.MuiBox-root 
{
    justify-content: flex-end !important;
    background-color: white !important;
    border-radius: 20px !important;
    }
.active
{
    font-family: 'Nunito' !important;
    background-color: #B6A269 !important;
    color: white;
    text-align: center !important;
    font-weight: bold !important;
    padding: 24px !important;
    cursor: pointer;
}
.inactive
{
    font-family: 'Nunito' !important;
    background-color: white !important;
    color: #333333;
    text-align: center;
    font-weight: bold !important;
    padding: 22px !important;
    cursor: pointer;
}
.regiclass
{
    font-family: 'Nunito' !important;
    background-color: #333333 !important;
    color: white !important;
    text-align: center;
    font-weight: bold !important;
    cursor: pointer;
}

.regiclasscustom{
    font-family: 'Nunito' !important;
 
    color: #000 !important;
    text-align: center;
    font-weight: bold !important;
    cursor: pointer;
}
.MenuText
{
    background-color: #333333 !important;
    color: #FFFFFF !important;
}
.settingsactive
{
    font-family: 'Nunito' !important;
    background-color: #B6A269 !important;
    color: white;
    text-align: center !important;
    font-weight: bold !important;
    padding: 6px !important;
    cursor: pointer;
}
.settingsinactive
{
    font-family: 'Nunito' !important;
    background-color: white !important;
    color: #333333;
    text-align: center;
    font-weight: bold !important;
    padding: 6px !important;
    cursor: pointer;
}

.Mui-active
{
    color:#333333 !important;
    text-decoration: underline #B6A269 2px;
    text-underline-position: under;
    font-size: 15px !important;
    font-family: 'Nunito'!important;
}
.Mui-completed
{
    font-size: 17px !important;
    font-family: 'Nunito'!important;
}
.MuiStepLabel-label
{
    font-weight: bold !important;
    color:#333333 !important;
    font-size: 12px !important;
    //font-size: 17px !important;
    font-family: 'Nunito'!important;
    padding-top: 15%;
    
}
.vehiclename
{
    font-family: 'Nunito'!important;
    font-weight: bold !important;
    color: #333333;
    font-size: 25px !important;
}
.MuiStep-root
{
    padding-left: 0px !important;
    padding-right: 0px !important;
}

.tripsdiv {
    border-top-left-radius:20; 
    border-top-right-radius:20; 
    border-bottom-left-radius:0;
    border-bottom-right-radius:0;
    -ms-overflow-style: none;
    scrollbar-width: none;
}

.sidebar {
    height: 200px;
    width: 350px;
    background-color: #3c3c3c;
    overflow-y: auto;
    
    /* hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  /* hide scrollbar for chrome, safari and opera */
  .sidebar::-webkit-scrollbar {
    display: none;
  }

  .inputRounded .MuiOutlinedInput-root{
    border-radius: 4px;
 }

 .MuiSelect-select{
    padding-right: 0px !important;
 }

 .react-tel-input .form-control {
    font-size: 12px !important;
    background: #FFFFFF;
    border: 1px solid #ccc;
    border-radius: 5px;
    padding: 7px 14px 7px 45px !important;
    width: 99% !important;
    height: 32px !important;
}

.react-tel-input .selected-flag .flag {
    top: 50% !important;
}

.react-datepicker{
    position:absolute !important;
    right: -340px !important;
    width:max-content;
    bottom: -150px !important;
}
  .dateClassfeild{
    padding: 5 !important;

  }
  
.texttimePicker{
    padding-top: 2 !important;
}